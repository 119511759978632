import { Link } from "react-router-dom";
const Sidebar = ({ side, closeSidebar }) => {
  return (
    <div
      className={`fixed top-0 ${side} sm:left-0 w-64 h-screen z-10 transition-all`}
      style={{'background':'black'}}
    >
      <i
        className="bi bi-x-lg absolute top-4 right-4 sm:hidden block cursor-pointer text-lg"
        onClick={closeSidebar}
      ></i>
      <div className="bg-white p-4">
        <img src="/logo.svg" alt="logo" />
      </div>



      <ul className="mt-8">

       <div className="px-8 py-3 text-white">Collections</div>

        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
            All Collections
          </div>
        </li>
        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
            Dress for her
          </div>
        </li>
        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
            Shoes for her
          </div>
        </li>
        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
            Shoes for him
          </div>
        </li>
   

        <div className="px-8 py-3 text-white">Price</div>

        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
            All prices
          </div>
        </li>
        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
            Up to $100
          </div>
        </li>
        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
          From $100 to $200
          </div>
        </li>
        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
          From $200 to $300
          </div>
        </li>
        <li className="px-8 cursor-pointer transition-all py-3 text-white flex items-center hover:bg-orange-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
          Above $300
          </div>
        </li>

      </ul>
    </div>
  );
};
export default Sidebar;
