const DetailsImage = ({ image }) => {
  return (
    <div className="w-full h-full sm:w-6/12 p-1">
     {image && (
        <img
          src={image}
          alt="image1"
          className="w-full h-auto object-cover"
        />
      )}
    </div>
  );
};

export default DetailsImage;
