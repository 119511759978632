import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { BsHandbag } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Search from "./Search";
import { toggleSearchBar } from "../../store/reducers/globalReducer";
const Nav = () => {
  const { userToken, user } = useSelector((state) => state.authReducer);
  const { searchBar } = useSelector((state) => state.globalReducer);
  const { items, total } = useSelector((state) => state.cartReducer);
  console.log(total);
  const dispatch = useDispatch();


  
  function signOut() {

    //alert("HERE")

    localStorage.setItem('session_token', '')

    window.location.href = '/login';

  }

  return (
    <>
      <nav className="nav">
        <div className="my-container">
          <div className="flex justify-between items-center">
            <Link to="/">
            <svg width="146" height="36" viewBox="0 0 146 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.2266 2.83056C32.6578 0.974729 35.6367 -0.0208168 38.6952 0.000330061C41.7537 0.0214767 44.7186 1.05812 47.1239 2.94739C48.1663 3.76612 48.3476 5.27487 47.5289 6.31725C46.7102 7.35964 45.2014 7.54095 44.159 6.72221C42.5903 5.49008 40.6567 4.81401 38.662 4.80022C36.6673 4.78642 34.7246 5.43569 33.139 6.64602C31.5534 7.85634 30.4148 9.55913 29.9021 11.4869C29.3895 13.4146 29.5317 15.458 30.3066 17.2961C31.0815 19.1342 32.445 20.6628 34.183 21.6417C35.9211 22.6206 37.935 22.9944 39.9086 22.7043C41.698 22.4413 43.1333 21.534 44.6701 19.9362C45.3074 19.2737 45.7784 18.3968 46.0546 17.4129L39.7949 17.4C38.4694 17.3973 37.3971 16.3205 37.3998 14.9951C37.4025 13.6696 38.4793 12.5973 39.8048 12.6L51.0697 12.6232L51.1336 14.9526C51.2124 17.8263 50.322 20.9844 48.1295 23.2638C46.1632 25.308 43.8168 26.9815 40.6066 27.4533C37.5805 27.8981 34.4924 27.3249 31.8274 25.8239C29.1625 24.3229 27.0717 21.9791 25.8836 19.1607C24.6954 16.3423 24.4773 13.209 25.2634 10.2532C26.0495 7.29733 27.7953 4.68639 30.2266 2.83056Z" fill="#0B0B0B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M61.9999 13.2004C59.3489 13.2004 57.1999 15.3494 57.1999 18.0004C57.1999 20.6514 59.3489 22.8004 61.9999 22.8004C64.6509 22.8004 66.7999 20.6514 66.7999 18.0004C66.7999 15.3494 64.6509 13.2004 61.9999 13.2004ZM52.3999 18.0004C52.3999 12.6985 56.698 8.40039 61.9999 8.40039C67.3018 8.40039 71.5999 12.6985 71.5999 18.0004C71.5999 23.3023 67.3018 27.6004 61.9999 27.6004C56.698 27.6004 52.3999 23.3023 52.3999 18.0004Z" fill="#0B0B0B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M75.2002 0.599609H93.8002V5.39961H80.0002V11.0996H93.8002V15.8996H80.0002V26.3996H75.2002V0.599609Z" fill="#0B0B0B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M101 0.599609V26.3996H96.2002V0.599609H101Z" fill="#0B0B0B"/>
<g clip-path="url(#clip0_1333_22236)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.942 6.28711L112.88 17.2279L118.689 6.92971L122.87 9.28805L115.091 23.0784L106.726 38.0646L102.535 35.7252L110.208 21.9775L101.888 8.85771L105.942 6.28711Z" fill="#0B0B0B"/>
</g>
<g clip-path="url(#clip1_1333_22236)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M125.142 6.28711L132.08 17.2279L137.889 6.92971L142.07 9.28805L134.314 23.0358L126.643 38.029L122.37 35.8427L129.439 22.0267L121.088 8.85771L125.142 6.28711Z" fill="#0B0B0B"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.799805 2.4002C0.799805 1.73745 1.33706 1.2002 1.9998 1.2002H3.1998C3.86255 1.2002 4.3998 1.73745 4.3998 2.4002C4.3998 3.06294 3.86255 3.6002 3.1998 3.6002H1.9998C1.33706 3.6002 0.799805 3.06294 0.799805 2.4002ZM6.1998 2.4002C6.1998 1.73745 6.73706 1.2002 7.39981 1.2002H35.5998C36.2625 1.2002 36.7998 1.73745 36.7998 2.4002C36.7998 3.06294 36.2625 3.6002 35.5998 3.6002H7.39981C6.73706 3.6002 6.1998 3.06294 6.1998 2.4002Z" fill="#FDDD16"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 8.4002C8 7.73745 8.53726 7.2002 9.2 7.2002H10.4C11.0627 7.2002 11.6 7.73745 11.6 8.4002C11.6 9.06294 11.0627 9.6002 10.4 9.6002H9.2C8.53726 9.6002 8 9.06294 8 8.4002ZM13.4 8.4002C13.4 7.73745 13.9373 7.2002 14.6 7.2002H22.4C23.0627 7.2002 23.6 7.73745 23.6 8.4002C23.6 9.06294 23.0627 9.6002 22.4 9.6002H14.6C13.9373 9.6002 13.4 9.06294 13.4 8.4002Z" fill="#FDDD16"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.1997 14.4002C15.1997 13.7375 15.737 13.2002 16.3997 13.2002H17.5997C18.2624 13.2002 18.7997 13.7375 18.7997 14.4002C18.7997 15.0629 18.2624 15.6002 17.5997 15.6002H16.3997C15.737 15.6002 15.1997 15.0629 15.1997 14.4002ZM20.5997 14.4002C20.5997 13.7375 21.137 13.2002 21.7997 13.2002H34.3997C35.0624 13.2002 35.5997 13.7375 35.5997 14.4002C35.5997 15.0629 35.0624 15.6002 34.3997 15.6002H21.7997C21.137 15.6002 20.5997 15.0629 20.5997 14.4002Z" fill="#FDDD16"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.3999 19.2C4.3999 18.5373 4.93716 18 5.5999 18H6.7999C7.46264 18 7.9999 18.5373 7.9999 19.2C7.9999 19.8627 7.46264 20.4 6.7999 20.4H5.5999C4.93716 20.4 4.3999 19.8627 4.3999 19.2ZM9.7999 19.2C9.7999 18.5373 10.3372 18 10.9999 18H22.3999C23.0626 18 23.5999 18.5373 23.5999 19.2C23.5999 19.8627 23.0626 20.4 22.3999 20.4H10.9999C10.3372 20.4 9.7999 19.8627 9.7999 19.2Z" fill="#FDDD16"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.7998 23.9998C18.7998 23.3371 19.3371 22.7998 19.9998 22.7998H21.1998C21.8625 22.7998 22.3998 23.3371 22.3998 23.9998C22.3998 24.6625 21.8625 25.1998 21.1998 25.1998H19.9998C19.3371 25.1998 18.7998 24.6625 18.7998 23.9998ZM24.1998 23.9998C24.1998 23.3371 24.7371 22.7998 25.3998 22.7998H30.7998C31.4625 22.7998 31.9998 23.3371 31.9998 23.9998C31.9998 24.6625 31.4625 25.1998 30.7998 25.1998H25.3998C24.7371 25.1998 24.1998 24.6625 24.1998 23.9998Z" fill="#FDDD16"/>
<defs>
<clipPath id="clip0_1333_22236">
<rect width="21.6" height="25.2" fill="white" transform="translate(101.6 9.59961)"/>
</clipPath>
<clipPath id="clip1_1333_22236">
<rect width="21.6" height="25.2" fill="white" transform="translate(120.8 9.59961)"/>
</clipPath>
</defs>
</svg>
            </Link>
            <ul className="flex items-center">

              {
              //<li className="nav-li cursor-pointer">
              //  <FiSearch
              //    size={22}
              //    onClick={() => dispatch(toggleSearchBar())}
              //  />
              //</li>
              }

              <li className="nav-li">
                  <Link to="/marketplace" className="nav-link">
                    Shop
                  </Link>
                </li>

                <li className="nav-li">
                  <Link to="/orders" className="nav-link">
                    My Orders
                  </Link>
                </li>

                <li className="nav-li">
                  <Link to="/account" className="nav-link">
                    My Account
                  </Link>
                </li>

              {userToken ? (
                <li className="nav-li">
                  <Link to="/user" className="nav-link">
                    {user?.name}
                  </Link>
                </li>
              ) : (
                <li className="nav-li">
                  <Link to="/login" className="nav-link">
                    sign in
                  </Link>
                </li>
              )}

                <li className="nav-li">
                  <div className="nav-link" onClick={signOut}>
                    sign out
                  </div>
                </li>

              <li className="nav-li relative">
                <Link to="/cart">
                  <BsHandbag size={20} />
                  <span className="nav-circle">{items}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Search />
    </>
  );
};
export default Nav;
