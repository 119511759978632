import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import { useRandomCategoriesQuery } from "../../store/services/categoryService";
import Spinner from "../Spinner";
const Slider = () => {
  //const { data, isFetching } = useRandomCategoriesQuery();

  const isFetching = ''
  //const data = ['1','2','3','4']

  //console.log(data, isFetching);

  //alert("asda")

  return isFetching ? (
    <div className="my-container h-[70vh] flex items-center justify-center">
      <Spinner />
    </div>
  ) : (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination]}
      className="mySwiper"
    >

          <SwiperSlide className="slide" key={'1'}>
            <div className={`slide-img`}>
              <img
                src={`./images/slider/1.jpg`}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="absolute inset-0 w-full h-full bg-black/50">
              <div className="my-container h-[70vh] flex flex-col items-center justify-center">
                <h1 className="text-white text-xl font-medium capitalize">
                  Collection 1
                </h1>
                <div className="mt-10">
                  <Link
                    to={`/marketplace`}
                    className="btn btn-indigo text-sm"
                  >
                    browse collections
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide" key={'2'}>
            <div className={`slide-img`}>
              <img
                src={`./images/slider/2.jpg`}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="absolute inset-0 w-full h-full bg-black/50">
              <div className="my-container h-[70vh] flex flex-col items-center justify-center">
                <h1 className="text-white text-xl font-medium capitalize">
                Collection 2
                </h1>
                <div className="mt-10">
                  <Link
                     to={`/marketplace`}
                    className="btn btn-indigo text-sm"
                  >
                    browse collections
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide" key={'3'}>
            <div className={`slide-img`}>
              <img
                src={`./images/slider/3.jpg`}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="absolute inset-0 w-full h-full bg-black/50">
              <div className="my-container h-[70vh] flex flex-col items-center justify-center">
                <h1 className="text-white text-xl font-medium capitalize">
                Collection 3
                </h1>
                <div className="mt-10">
                  <Link
                     to={`/marketplace`}
                    className="btn btn-indigo text-sm"
                  >
                    browse collections
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className="slide" key={'4'}>
            <div className={`slide-img`}>
              <img
                src={`./images/slider/4.jpg`}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="absolute inset-0 w-full h-full bg-black/50">
              <div className="my-container h-[70vh] flex flex-col items-center justify-center">
                <h1 className="text-white text-xl font-medium capitalize">
                Collection 4
                </h1>
                <div className="mt-10">
                  <Link
                     to={`/marketplace`}
                    className="btn btn-indigo text-sm"
                  >
                    browse collections
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
    
    </Swiper>
  );
};

export default Slider;
