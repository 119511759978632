import { useState, useEffect } from "react";
import Categories from "../../components/home/Categories";

import ReactDOM from 'react-dom';

import currency from "currency-formatter";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { AiFillStar } from "react-icons/ai";

import ProductCard from "../../components/home/ProductCard";
import { useTheme } from '@mui/material/styles';
import * as React from 'react';

import HomeProduct from "../../components/home/HomeProduct";
import Nav from "../../components/home/Nav";
import Slider from "../../components/home/Slider";
import { useRandomCategoriesQuery } from "../../store/services/categoryService";

import SidebarMarketplace from "../../components/SidebarMarketplace";

import ImageGallery from "react-image-gallery";
import AdminNav from "../../components/AdminNav";


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';


import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import PropTypes from 'prop-types';


import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';


import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';


import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';



const Marketplace = () => {
  //const { data, isFetching } = useRandomCategoriesQuery();

  const isFetching = ''

  const [side, setSide] = useState('-left-64')
  const openSidebar = () => {
      setSide("left-0");
  }
  const closeSidebar = () => {
      setSide('-left-64');
  }

  //alert("MPLC")




  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);


  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength - 3) + '...';
    } else {

      if (text == 'undefined') { return ''; }
      else { return text; }
      
    }
  }


  function getProducts() {

    var http = typeof window !== "undefined" ? new XMLHttpRequest() : ""
            var url = 'https://api.goflyy.com/v1/client/getAmazonProducts';
            var data = typeof window !== "undefined" ? new FormData() : ""
            
            data.append("price_min", "");
            data.append("price_max", "");
            data.append("limit", "999");
          
            http.open('POST', url, true);
            http.send(data)

            http.onreadystatechange = function() { 

              if(http.readyState == 4 && http.status == 200) {

                var response = http.responseText;
                const resp_obj = JSON.parse(response); 


                ReactDOM.render(
                  <>
                    <Box sx={{ width: '100%' }}  style={{'margin-top':'100px', 'margin-bottom':'40px'}}>
                      <Stepper alternativeLabel activeStep={3} connector={<QontoConnector />}>
                          <Step key={'label'}>
                            <StepLabel StepIconComponent={QontoStepIcon}><div style={{'color':'white', 'font-size':'30px'}}>Pay $25 deposit</div></StepLabel>
                          </Step>
                          <Step key={'label'}>
                            <StepLabel StepIconComponent={QontoStepIcon}><div style={{'color':'white', 'font-size':'30px'}}>Get a few sizes</div></StepLabel>
                          </Step>
                          <Step key={'label'}>
                            <StepLabel StepIconComponent={QontoStepIcon}><div style={{'color':'white', 'font-size':'30px'}}>Keep what fit best</div></StepLabel>
                          </Step>
                      </Stepper>
                    </Box>
                  </>
                    , document.getElementById("timeline")
                  );


                //alert(resp_obj.products_list)

                var product_number = 0;

                for (const key in resp_obj.products_list) {
                
                    var product = resp_obj.products_list[key];

                    //alert(product.original_retail_price)

                    if (!product.original_retail_price) { continue; }


                    const ColorButton = styled(Button)(({ theme }) => ({
                      color: theme.palette.getContrastText(purple[500]),
                      backgroundColor: '#FF8C00',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }));

                    const ColorButton2 = styled(Button)(({ theme }) => ({
                      color: '#FF8C00',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: 'black',
                      },
                    }));




                    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

                    const images2 = [
                      {
                        label: 'San Francisco – Oakland Bay Bridge, United States',
                        imgPath:
                          'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
                      },
                      {
                        label: 'Bird',
                        imgPath:
                          'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
                      },
                      {
                        label: 'Bali, Indonesia',
                        imgPath:
                          'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
                      },
                      {
                        label: 'Goč, Serbia',
                        imgPath:
                          'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
                      },
                    ];


                    

                    const maxSteps = images2.length;

                    const handleNext = () => {
                      setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    };

                    const handleBack = () => {
                      setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    };

                    const handleStepChange = (step) => {
                      setActiveStep(step);
                      //console.log(step)
                    };





                    
                    //console.log(value)
                    //alert(value.title)

                    //const value_obj = JSON.parse(value2); 
                    //alert(value_obj.title)

                    //document.getElementById("products").innerHTML = '<div id="product-' + key + '"></div>'

                    ReactDOM.render(

                      
                              <>

                            {/*
                              <a href={`/product/${product.product_id}`}>
                                <div className="w-full">
                                  <img
                                    src={product.main_image}
                                    alt={product.title}
                                    className="w-full h-[310px] object-cover"
                                  />
                                </div>
                                <p className="capitalize text-base font-medium text-black my-2.5">
                                  {product.title}
                                </p>
                                <div className="flex items-center">
                                  <div className="flex items-center space-x-2 mb-1">
                                    <span>{product.stars}</span>
                                    <AiFillStar color="orange" />
                                    <span>({product.review_count})</span>
                                  </div>
                                </div>
                                <div className="flex justify-between">
                                  <span className="text-lg font-medium text-black">
                                    {currency.format(product.price/100, { code: "USD" })}
                                  </span>
                                  <span className="text-lg font-medium text-gray-600 line-through">
                                    {currency.format((product.price/90), { code: "USD" })}
                                  </span>
                                </div>
                              </a>
                            */}
                          
                              <Card >
                                  <div className="price-tag">{currency.format(product.price/100, { code: "USD" })}</div>
                                  <CardMedia
                                    sx={{ height: 640 }}
                                    image={product.main_image}
                                    title={product.title}
                                  />
                                

                                <CardContent
                                 sx={{ height: 260 }} style={{'color':'white','backgroundColor':'black'}}>

                                  <div className="flex items-center">
                                    <div className="flex items-center space-x-2 mb-1">
                                      <span>{product.stars}</span>
                                      <AiFillStar color="orange" />
                                      <span>({product.review_count})</span>
                                    </div>
                                  </div>

                                  <Typography gutterBottom variant="h6" component="div">
                                  {product.title}
                                  </Typography>
                                 
                                  <Typography gutterBottom variant="h7" component="div" className="text-gray-600">
                                  {truncateText(String(product.product_description), 150)}
                                  </Typography>
                                   
                                  
                                </CardContent>
                                
                                <CardActions style={{'text-align': 'right', 'color':'white','backgroundColor':'black'}}>

                                  <ColorButton style={{'margin':'10px'}} href={`/product/${product.product_id}`} size="large" variant="contained">Try few sizes</ColorButton>
                                
                                </CardActions>
                             
                              </Card>

                             
                              </>

                    , document.getElementById("product-" + product_number)
                    );

                    var product_number = product_number + 1;
                }

              }

            }

  }

  useEffect(() => {

    getProducts();

  }, []);


  const numberOfProducts = 53;


  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#FF8C00',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#FF8C00',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  
  const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#FF8C00',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#FF8C00',
      zIndex: 1,
      fontSize: 20,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }));
  
  function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
  

  
  
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );







  return (
    <>
      <Nav />

      <SidebarMarketplace side={side} closeSidebar={closeSidebar} />
      
      <div className="absolute left-0 sm:left-64 md:left-64 md:left-64 lg:left-64 xl:left-64 2xl:left-64 background-marketplace" style={{'padding-left':'10px', 'padding-right':'10px'}} >

        <div id="timeline"></div>

        <div className="flex flex-wrap">
          {Array.from({ length: numberOfProducts }).map((_, i) => (
            <div key={i} className="w-full sm:w-12/12 md:w-6/12 xl:w-3/12 px-3 py-3">
              <m id={`product-${i}`}></m>
            </div>
          ))}
        </div>
        
      </div>

      

    </>
  );
};
export default Marketplace;
