import { Link, useParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { useGetProductQuery } from "../../store/services/productService";
import Nav from "../../components/home/Nav";
import DetailsCard from "../../components/home/DetailsCard";
import ProductLoader from "../../components/home/ProductLoader";
import { useLocation } from 'react-router-dom'; 


const Product = () => {
  //const { name } = useParams();
  //const { data, isFetching } = useGetProductQuery(name);


  const location = useLocation();
  let amz_product_id = location.pathname.replace("/product/", "");

  //alert(location.pathname)

  //alert("dfsdf")

  const data = ''
  const isFetching = ''

  console.log(data, isFetching);
  return (
    <>

      <Nav />
      
        <div className="background-marketplace">

          <div className="my-container py-24">
            <>
              <ul className="flex items-center">
                <li className="capitalize text-gray-600">
                  <Link to="/">home</Link>
                </li>
                <FiChevronRight className="block mx-2" />
                <li className="capitalize text-gray-600">
                  <Link to={`/marketplace/`}>
                    Marketplace
                  </Link>
                </li>
                <FiChevronRight className="block mx-2" />
                <li className="capitalize text-gray-600">
                  <Link to={`/product/` + amz_product_id}>{amz_product_id}</Link>
                </li>
              </ul>
              <DetailsCard product={{'_id':'1','title':'Google','image1':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image2':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image3':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','price':'1000', 'description':'iojronlidfba','discount':'5', 'stars': '4.5', 'reviews':'112', 'sizes':[{'name':'X'}, {'name':'XL'}, {'name':'XXL'}], 'colors':[{'color':'Red'}, {'color':'Blue'}, {'color':'Green'}] }} />
            </>
          </div>

        </div>
        
      </>
  );
};

export default Product;
