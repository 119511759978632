import Categories from "../../components/home/Categories";

import Pagination from "../../components/Pagination";
import ProductCard from "../../components/home/ProductCard";
import HomeProduct from "../../components/home/HomeProduct";
import Nav from "../../components/home/Nav";
import Slider from "../../components/home/Slider";
import { useRandomCategoriesQuery } from "../../store/services/categoryService";

import './style.css'

const Home = () => {
  //const { data, isFetching } = useRandomCategoriesQuery();

  const isFetching = ''

  return (
    <>
      <Nav />
      <div className="mt-[70px]">

        <Slider />
     
      </div>
      <div className="my-container mt-10">
        <Categories />
      
        <HomeProduct category={'asdaksjdhaksjhdkahjsd'} key={'1'} />

        
        
      </div>






    </>
  );
};
export default Home;
