import Routing from "./routes/Routing"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
function App() {
  return (
   <Routing />
  );
}
export default App;
