import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';

import Categories from "../../components/home/Categories";

import ProductCard from "../../components/home/ProductCard";

import HomeProduct from "../../components/home/HomeProduct";
import Nav from "../../components/home/Nav";
import Slider from "../../components/home/Slider";
import { useRandomCategoriesQuery } from "../../store/services/categoryService";

import SidebarAdmin from "../../components/SidebarAdmin";
//import Select from "react-dropdown-select";
import Box from '@mui/material/Box';
import AdminNav from "../../components/AdminNav";

import * as React from 'react';

import Button from '@mui/material/Button';

import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import './style.css'

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Stack from '@mui/material/Stack';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@mui/material';
import Select from '@mui/material/Select';

import CircularProgress from '@mui/joy/CircularProgress';

const Marketplace = () => {
  //const { data, isFetching } = useRandomCategoriesQuery();

  const isFetching = ''

  const [side, setSide] = useState('-left-64')
  const openSidebar = () => {
      setSide("left-0");
  }
  const closeSidebar = () => {
      setSide('-left-64');
  }

  //alert("MPLC")

  //CURRENCY FORMATTER
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD' // Change this to your desired currency code (e.g., 'EUR' for Euro, 'JPY' for Japanese Yen, etc.)
  });

  //CLOSE POPUP WINDOW
  function closePopup(){
    document.querySelector("#close-popup").addEventListener("click", function(event) {
      event.preventDefault();
      window.location.replace("#");
    });
    window.location.replace("#close-popup");
  }
    
  //PROCESS ACTION POPUP CONTECT
  function setValues(values) {

    //alert(JSON.stringify(values))

    var order_type = values[0].value.type;
    var order_id = values[0].value.order_id;
    var product_id = values[0].value.product_id;

    window.location.href = '#popup-product';

    if (order_type == 'Purchase') {

      ReactDOM.render(
        <>
          <h3>Purchase</h3> 

          Order ID: {order_id}
          <br></br>
          Product ID: {product_id}

          <div className="popup-table">
            <div className="popup-tableBody">
              <div className="popup-tableRow">
              <div className="popup-tableCell">1</div>
              <div className="popup-tableCell">2</div>
              </div>
              <div className="popup-tableRow">
              <div className="popup-tableCell">3</div>
              <div className="popup-tableCell">4</div>
              </div>
              <div className="popup-tableRow">
              <div className="popup-tableCell">5</div>
              <div className="popup-tableCell">6</div>
              </div>
            </div>
          </div>
        </>
      , document.getElementById("popup-content")
      );
    }

    if (order_type == 'Return') {

      ReactDOM.render(
        <>
          <h3>Return</h3> 

          Order ID: {order_id}
          <br></br>
          Product ID: {product_id}

          <div className="popup-table">
            <div className="popup-tableBody">
              <div className="popup-tableRow">
              <div className="popup-tableCell">1</div>
              <div className="popup-tableCell">2</div>
              </div>
              <div className="popup-tableRow">
              <div className="popup-tableCell">3</div>
              <div className="popup-tableCell">4</div>
              </div>
              <div className="popup-tableRow">
              <div className="popup-tableCell">5</div>
              <div className="popup-tableCell">6</div>
              </div>
            </div>
          </div>
        </>
      , document.getElementById("popup-content")
      );

    }
  }




  function QuestionIconPopup3() {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className="question-popup">
        <div className="question-icon" onClick={togglePopup}>
          
        </div>
        {isOpen && (
          <div className="popup-content">
            Once the return window is closed, any items with a "Tryout" 
            status will be automatically purchased, and the payment will 
            be automatically charged to your selected payment method.
          </div>
        )}
      </div>
    );
  }

  function QuestionIconPopup2() {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className="question-popup">
        <div className="question-icon" onClick={togglePopup}>
         
        </div>
        {isOpen && (
          <div className="popup-content">
            When your delivery is in transit status, it signifies our dedicated efforts to 
            swiftly deliver your item(s). Typically, this process is completed within a maximum of two business days. 
          </div>
        )}
      </div>
    );
  }

  function QuestionIconPopup1() {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className="question-popup">
        <div className="question-icon" onClick={togglePopup}>
          
        </div>
        {isOpen && (
          <div className="popup-content">
            Your order has been successfully placed. Please sit back and relax while we work on delivering it to you.
          </div>
        )}
      </div>
    );
  }

  const troutHours = 96;

  function insertTryonCountdown(delivered_at, countdown_id){

    //alert("delivered_at", delivered_at)
    //alert("countdown_id", countdown_id)
    
    //COUNT DOWN TRYOUT TIME
    // Set the target date and time (in UTC)
    var targetDate = new Date(delivered_at).getTime();

    // Calculate the target date and time for 72 hours later
   
    
    // Update the countdown every 1 second
    var countdownInterval = setInterval(updateCountdown(targetDate), 1000);

    function updateCountdown(targetDate) {

      //alert("HERE1", countdown_id)
      
      var targetDateHoursLater = targetDate + (troutHours * 60 * 60 * 1000); // 72 hours in milliseconds
      var currentDate = new Date().getTime();
      var timeRemaining = targetDateHoursLater - currentDate;

      if (timeRemaining <= 0) {
        //alert("HERE2")
        clearInterval(countdownInterval);
        //alert("HERE3", countdown_id)
        try{document.getElementById(countdown_id).innerHTML = "Closed";} 
        catch{}
        
      } else {

        
        var hours = Math.floor(timeRemaining / (1000 * 60 * 60));
        var minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        //var seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        //const countdownText = `Will be closed in ${hours}h ${minutes}m ${seconds}s left`;
        var countdownText = `Will be closed in ${hours}h ${minutes}m`;
        //alert("countdownText" + countdownText)

        try{document.getElementById(countdown_id).innerHTML = countdownText;}
        catch{}
      }
    }

  }



    function callAPI(action) {

      document.getElementById("button1_disabled").hidden = false;
      document.getElementById("button1").hidden = true;

      document.getElementById("button2_disabled").hidden = false;
      document.getElementById("button2").hidden = true;

      document.getElementById("button3_disabled").hidden = false;
      document.getElementById("button3").hidden = true;

      document.getElementById("button4_disabled").hidden = false;
      document.getElementById("button4").hidden = true;

        //alert(action)

        var http = typeof window !== "undefined" ? new XMLHttpRequest() : ""
        var url = 'https://api.goflyy.com/v1/retailer/getZonningReport';
        var data = typeof window !== "undefined" ? new FormData() : ""

        //alert (localStorage.getItem('report_email'))
        //alert (localStorage.getItem('report_from'))
        //alert (localStorage.getItem('report_to'))
        
        data.append("user_email", localStorage.getItem('report_email'));
        data.append("start_datetime", localStorage.getItem('report_from'));
        data.append("end_datetime", localStorage.getItem('report_to'));

        if (action == '1') {
          data.append("create_stripe_invoice", "false");
          data.append("send_invoice_to_retailer", "false");
          data.append("charge_default_payment_method", "false");

          document.getElementById("button1_progress").hidden = false;
        }
        if (action == '2') {
          data.append("create_stripe_invoice", "true");
          data.append("send_invoice_to_retailer", "false");
          data.append("charge_default_payment_method", "false");

          document.getElementById("button2_progress").hidden = false;
        }
        if (action == '3') {
          data.append("create_stripe_invoice", "true");
          data.append("send_invoice_to_retailer", "true");
          data.append("charge_default_payment_method", "false");

          document.getElementById("button3_progress").hidden = false;
        }
        if (action == '4') {
          data.append("create_stripe_invoice", "true");
          data.append("send_invoice_to_retailer", "true");
          data.append("charge_default_payment_method", "true");

          document.getElementById("button4_progress").hidden = false;
        }

        http.open('POST', url, true);
        http.setRequestHeader("token", "SH3IGUYI6LASD");
        http.send(data)

        http.onreadystatechange = function() {

          if(http.readyState == 4 && http.status == 200) {

              var response = http.responseText;
              const resp_obj = JSON.parse(response); 

              if (response == 'null') {
                alert ("Error: No data for selected datetime range")
              } else {
                alert("Successfully executed")
              }
              window.location.href = '/admin';

            }
        }
    }
  


    useEffect(() => {

      //loadOrders();

      if (localStorage.getItem('session_token') !== 'wefhiuhiudhsiufhisdudhfsuhsldflh') {
        window.location.href = '/login';
      }

    }, []);





    const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };
  
    const list = (anchor) => (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  
    const todayAtNoon = dayjs().set('hour', 12).startOf('hour');
    const todayAt3PM = dayjs().set('hour', 15).startOf('hour');


    useEffect(() => {

      localStorage.setItem('report_email', '');
      localStorage.setItem('report_from', '');
      localStorage.setItem('report_to', '');

    }, []);



function checkActiveButton() {

  if (localStorage.getItem('report_email') !== '' && localStorage.getItem('report_from') !== '' && localStorage.getItem('report_to') !== '') {

    //alert("HERE")
    document.getElementById("button1_disabled").hidden = true;
    document.getElementById("button1").hidden = false;

    document.getElementById("button2_disabled").hidden = true;
    document.getElementById("button2").hidden = false;

    document.getElementById("button3_disabled").hidden = true;
    document.getElementById("button3").hidden = false;

    document.getElementById("button4_disabled").hidden = true;
    document.getElementById("button4").hidden = false;

  }

}


    function setValues(type, values){

        //alert(JSON.stringify(values))

        //if (type == 'email') { localStorage.setItem('email', values); }
        
        if (type == 'from') {
          const date = new Date(values);
          const formattedDateTime = date.toISOString().slice(0, 19).replace('T', ' ');
          localStorage.setItem('report_from', formattedDateTime); 
          //alert(formattedDateTime);
        }

        if (type == 'to') {
          const date = new Date(values);
          const formattedDateTime = date.toISOString().slice(0, 19).replace('T', ' ');
          localStorage.setItem('report_to', formattedDateTime); 
          //alert(formattedDateTime);
        }

        //alert(formatDate(values))
        
        //alert(formatDate(values))
        checkActiveButton()
        
      }

    const handleChange = (event) => {
      //alert(event.target.value);
      localStorage.setItem('report_email', event.target.value);
      //setAge(event.target.value);
      checkActiveButton()
    };


  return (
  <>

      <Nav />

      <SidebarAdmin side={side} closeSidebar={closeSidebar} />
      
      <div className="absolute left-0 sm:left-64 md:left-64 md:left-64 lg:left-64 xl:left-64 2xl:left-64 py-12 right-10 mx-16 " >
        <div className="flex flex-wrap" >

          <div id="data-section"><br></br><br></br><br></br>

          <p>Retailer</p>

          <Box sx={{ width: 400 }}>
            <FormControl fullWidth>
             
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={age}
                //label="Retailer"
                //onChange={(values) => setValues("email", values)}
                onChange={handleChange}
              >
                <MenuItem value={'shefal@armoire.style'}>Armoire (shefal@armoire.style)</MenuItem>
                <MenuItem value={'mr.klimanov@gmail.com'}>Privaclouds Test (mr.klimanov@gmail.com)</MenuItem>

              </Select>
            </FormControl>
          </Box>

          <br></br>
          <Box sx={{ width: 400 }}>
          
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DemoItem label="Report Start Date and Time (PT)">
                  <DateTimePicker onChange={(values) => setValues("from", values)} disableFuture />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
            </Box>
          <br></br>
            <Box sx={{ width: 400 }}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DemoItem label="Report End Date and Time (PT)">
                  <DateTimePicker onChange={(values) => setValues("to", values)} disableFuture />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>

            </Box>
            <br></br>
            {/* <Stack spacing={2} direction="row"></Stack>
            */}
    
            <div id="button1_disabled">
              <Button variant="contained" disabled>Action 1</Button>

              <m id="button1_progress" hidden>
                <CircularProgress style={{'margin-left':'20px'}} size="sm"/>
              </m>
            </div>

            <div id="button1" hidden>
              <Button variant="contained" onClick={() => callAPI('1')}>Action 1</Button>

             

            </div>

            <p>The system will send CSV report to tech@goflyy.com but will not create 
              invoice in Stripe, will not Send invoice and payment link to retailer and will not charge retailer.</p>
          
              <br></br>
            
            <div id="button2_disabled">
              <Button variant="contained" disabled>Action 2</Button>

              <m id="button2_progress" hidden>
                <CircularProgress style={{'margin-left':'20px'}} size="sm"/>
              </m>
            </div>

            <div id="button2" hidden>
              <Button variant="contained" onClick={() => callAPI('2')}>Action 2</Button>

             

            </div>
              <p>The system will send CSV report to tech@goflyy.com and create 
                invoice in Stripe but will not Send invoice and payment link to retailer and will not charge retailer.</p>

              <br></br>

            <div id="button3_disabled">
              <Button variant="contained" disabled>Action 3</Button>

              <m id="button3_progress" hidden>
                <CircularProgress style={{'margin-left':'20px'}} size="sm"/>
              </m>
            </div>

            <div id="button3" hidden>
              <Button variant="contained" onClick={() => callAPI('3')}>Action 3</Button>

             

            </div>
            
              <p>The system will send CSV report to tech@goflyy.com and create invoice in 
                Stripe and Send invoice and payment link to retailer but will not charge retailer. 
              </p>
          
              <br></br>

            <div id="button4_disabled">
              <Button variant="contained" disabled>Action 4</Button>

              <m id="button4_progress" hidden>
                <CircularProgress style={{'margin-left':'20px'}} size="sm"/>
              </m>
            </div>

            <div id="button4" hidden>
              <Button variant="contained" onClick={() => callAPI('4')}>Action 4</Button>

             
            </div>

              <p>The system will send CSV report to tech@goflyy.com and create invoice in Stripe and 
                Send invoice and payment link to retailer and charge retailer immediately.</p>
          
              <br></br>

          </div>

          

        </div>
      </div>

    </>
  );
};
export default Marketplace;