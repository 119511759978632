import { useState, useEffect } from "react";

import { useLocation } from 'react-router-dom'; 
import * as React from 'react';
import currency from "currency-formatter";
import { motion } from "framer-motion";
import h2p from "html2plaintext";
import htmlParser from "html-react-parser";
import toast, { Toaster } from "react-hot-toast";
import { BsCheck2 } from "react-icons/bs";
import { useDispatch } from "react-redux";
import DetailsImage from "./DetailsImage";
import Quantity from "./Quantity";
import { addCart } from "../../store/reducers/cartReducer";
import { discount } from "../../utils/discount";

import ReactDOM from 'react-dom';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { pink } from '@mui/material/colors';
import { orange } from '@mui/material/colors';

const DetailsCard = ({ product }) => {

  //alert()

  const location = useLocation();

  //alert(location.pathname)

  let amz_product_id = location.pathname.replace("/product/", "");

  function httpRequest(reqType, address, amz_product_id, asyncProc) {

    var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
    var data = typeof window !== "undefined" ? new FormData() : ""
    data.append("product_id", amz_product_id);
    if (asyncProc) {req.onreadystatechange = function() { 
        if (this.readyState == 4 && this.status == 200 && this.responseText !== null) {
          asyncProc(this);
        } 
      };
    }
    req.open(reqType, address, !(!asyncProc))
    req.send(data)
    return req;
    }

  var product_response = httpRequest("POST", "https://api.goflyy.com/v1/client/getAmazonProduct", amz_product_id);  
  var product_response_text = product_response.responseText;
  const product_response_obj = JSON.parse(product_response_text); 

  //alert(JSON.stringify(product_response_obj.products_body))










  const [sizeState, setSizeState] = useState(
    product?.sizes?.length > 0 && product.sizes[0].name
  );
  const [colorState, setColorState] = useState(
    product?.colors?.length > 0 && product.colors[0].color
  );
  const [quantity, setQuantity] = useState(1);
  const inc = () => {
    setQuantity(quantity + 1);
  };
  const dec = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  //alert(product.discount)

  const discountPrice = discount(product.price, 10);
  let desc = h2p(product.description);
  desc = htmlParser(desc);
  const dispatch = useDispatch();
  const addToCart = () => {
    const {
      ["colors"]: colors,
      ["sizes"]: sizes,
      ["createdAt"]: createdAt,
      ["updatedAt"]: updatedAt,
      ...newProduct
    } = product;
    newProduct["size"] = sizeState;
    newProduct["color"] = colorState;
    newProduct["quantity"] = quantity;
    const cart = localStorage.getItem("cart");
    const cartItems = cart ? JSON.parse(cart) : [];
    const checkItem = cartItems.find((item) => item._id === newProduct._id);
    //if (!checkItem) {
      dispatch(addCart(newProduct));
      cartItems.push(newProduct);
      localStorage.setItem("cart", JSON.stringify(cartItems));
      toast.success(`${newProduct.title} added to cart`);
    //} 
    
    //else {
    //  toast.error(`${newProduct.title} is already in cart`);
    //  return;
    //}
  };

//alert(JSON.stringify(product_response_obj.products_body.all_variants[0].variant_specifics[0].value))
//alert(JSON.stringify(product_response_obj.products_body.all_variants[1].variant_specifics[0].value))
//alert(JSON.stringify(product_response_obj.products_body.all_variants[2].variant_specifics[0].value))
//alert(JSON.stringify(product_response_obj.products_body.all_variants[3].variant_specifics[0].value))
//alert(JSON.stringify(product_response_obj.products_body.all_variants[4].variant_specifics[0].value))
//alert(JSON.stringify(product_response_obj.products_body.all_variants[5].variant_specifics[0].value))

const [state, setState] = React.useState({
  top: false,
  left: false,
  bottom: false,
  right: false,
});

const toggleDrawer = (anchor, open) => (event) => {
  if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    return;
  }

  setState({ ...state, [anchor]: open });
};


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];



const list = (anchor) => (
  <Box
    sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 600 }}
    role="presentation"
    onClick={toggleDrawer(anchor, false)}
    onKeyDown={toggleDrawer(anchor, false)}
  >
    
    <Box style={{'margin':'20px'}}>

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 550 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>1</TableCell>
            <TableCell align="right">2</TableCell>
            <TableCell align="right">3</TableCell>
            <TableCell align="right">4</TableCell>
            <TableCell align="right">5</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     
       
    <ColorButton onClick={addToCart} style={{'margin-top':'10px'}} size="large" variant="contained">Place Order</ColorButton>
   
    </Box>
    
   
  </Box>
);


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#FF8C00',
  '&:hover': {
    backgroundColor: 'black',
  },
}));

  return (
    <>
    <div id="product_card"></div>

    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-wrap -mx-5"
    >
      <Toaster />
      
      <div className="w-full order-2 md:order-1 md:w-6/12 p-5">
        <div className="flex flex-wrap -mx-1">
          <DetailsImage image={product_response_obj.products_body.images[0]} />
          <DetailsImage image={product_response_obj.products_body.images[1]} />
          <DetailsImage image={product_response_obj.products_body.images[2]} />
          <DetailsImage image={product_response_obj.products_body.images[3]} />
          <DetailsImage image={product_response_obj.products_body.images[4]} />
          <DetailsImage image={product_response_obj.products_body.images[5]} />
          <DetailsImage image={product_response_obj.products_body.images[6]} />
          <DetailsImage image={product_response_obj.products_body.images[7]} />
          <DetailsImage image={product_response_obj.products_body.images[8]} />
          <DetailsImage image={product_response_obj.products_body.images[9]} />
        </div>
      </div>
      <div className="w-full order-1 md:order-2 md:w-6/12 p-5">
        <h1 className="text-2xl font-bold text-white capitalize">
          {product_response_obj.products_body.title}
        </h1>
        <div className="flex justify-between my-5">
          <span className="text-2xl font-bold text-orange">
            {" "}
            {currency.format(product_response_obj.products_body.price/100, { code: "USD" })}
          </span>
          <span className="text-xl line-through text-gray-500">
            {currency.format(product_response_obj.products_body.price/90, { code: "USD" })}
          </span>
        </div>

        <h3 className="text-base font-medium capitalize text-gray-600 mb-2 mt-3">
          description
        </h3>
        <div className="mt-4 leading-[27px] description text-white">{product_response_obj.products_body.product_description}</div>
        <br></br>

            <h3 className="text-base font-medium capitalize text-gray-600 mb-3">
              Select size
            </h3>
            <div className="flex flex-wrap -mx-1">
              {Array.from({ length: 3 }).map((_, i) => (
                
                <ColorButton style={{'margin-right':'10px'}} onClick={() => setSizeState(product_response_obj.products_body.all_variants[i].variant_specifics[0].value)} size="large" variant="contained">{product_response_obj.products_body.all_variants[i].variant_specifics[0].value}</ColorButton>

              ))}
            </div>

        {/*product.colors.length > 0 && (
          <>
            <h3 className="text-base font-medium capitalize text-gray-600 mb-2 mt-3">
              colors
            </h3>
            <div className="flex flex-wrap -mx-1">
              {product.colors.map((color) => (
                <div
                  key={color.color}
                  onClick={() => setColorState(color.color)}
                  className="border border-gray-300 rounded m-1 p-1 cursor-pointer"
                >
                  <span
                    className="min-w-[40px] min-h-[40px] rounded flex items-center justify-center"
                    style={{ backgroundColor: color.color }}
                  >
                    {colorState === color.color && (
                      <BsCheck2 className="text-white" size={20} />
                    )}
                  </span>
                </div>
              ))}
            </div>
          </>
        )}

        */}
        

        <FormControl>

        <h3 className="text-base font-medium capitalize text-gray-600 py-5">
              Select tryon option
            </h3>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="I want selected size only"
            name="radio-buttons-group"
            style={{'color':'white'}}
          >
            <FormControlLabel  value="female" control={<Radio 
            
            sx={{
              color: orange[800],
              '&.Mui-checked': {
                color: orange[600],
              },
            }}
            
            />} label="I want selected size only" />
            <FormControlLabel value="male" control={<Radio 
            
            sx={{
              color: orange[800],
              '&.Mui-checked': {
                color: orange[600],
              },
            }}
            
            />} label="I want selected size + a smaller one to try" />
            <FormControlLabel value="other" control={<Radio 
            
            sx={{
              color: orange[800],
              '&.Mui-checked': {
                color: orange[600],
              },
            }}
            
            />} label="I want selected size + a larger one to try" />
          </RadioGroup>
        </FormControl>


        <div className="flex -mx-3 items-center">

          {/*
          <div className="w-full sm:w-6/12 p-3">
            <Quantity quantity={quantity} inc={inc} dec={dec} />
          </div>
           */}

          <div className="w-full sm:w-6/12 p-3">


          <ColorButton onClick={toggleDrawer('right', true)} style={{'margin-top':'10px'}} size="large" variant="contained">Add to cart</ColorButton>

          </div>
        </div>
      
      </div>
    </motion.div>

    <div style={{'margin-top':'200px'}}>
     
        <React.Fragment key={'right'}>
          
          <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            {list('right')}
          </Drawer>
        </React.Fragment>

    </div>

    </>
  );
};

export default DetailsCard;
