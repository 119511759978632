import { Link } from "react-router-dom";
const Sidebar = ({ side, closeSidebar }) => {
  return (
    <div
      className={`fixed top-0 ${side} sm:left-0 w-64 h-screen bg-gray-300 z-10 transition-all`}
    >
      <i
        className="bi bi-x-lg absolute top-4 right-4 sm:hidden block cursor-pointer text-lg"
        onClick={closeSidebar}
      ></i>
      <div className="bg-white p-4">
        <img src="/logo.svg" alt="logo" />
      </div>

      <ul className="mt-8">

        <li className="px-8 cursor-pointer transition-all py-3 text-black flex items-center hover:bg-gray-400">
          <i className="bi bi-bag-check mr-2 inline-block text-lg"></i>{" "}
          <div className="text-base capitalize">
            Auto-Invoicing
          </div>
        </li>
       
      </ul>
    </div>
  );
};
export default Sidebar;
