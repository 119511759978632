import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';

import Categories from "../../components/home/Categories";

import ProductCard from "../../components/home/ProductCard";

import HomeProduct from "../../components/home/HomeProduct";
import Nav from "../../components/home/Nav";
import Slider from "../../components/home/Slider";
import { useRandomCategoriesQuery } from "../../store/services/categoryService";

import SidebarOrders from "../../components/SidebarOrders";
import Select from "react-dropdown-select";
import Box from '@mui/material/Box';
import AdminNav from "../../components/AdminNav";

import * as React from 'react';

import Button from '@mui/material/Button';

import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import './style.css'

const Marketplace = () => {
  //const { data, isFetching } = useRandomCategoriesQuery();

  const isFetching = ''

  const [side, setSide] = useState('-left-64')
  const openSidebar = () => {
      setSide("left-0");
  }
  const closeSidebar = () => {
      setSide('-left-64');
  }

  //alert("MPLC")

  //CURRENCY FORMATTER
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD' // Change this to your desired currency code (e.g., 'EUR' for Euro, 'JPY' for Japanese Yen, etc.)
  });

  //CLOSE POPUP WINDOW
  function closePopup(){
    document.querySelector("#close-popup").addEventListener("click", function(event) {
      event.preventDefault();
      window.location.replace("#");
    });
    window.location.replace("#close-popup");
  }
    
  //PROCESS ACTION POPUP CONTECT
  function setValues(values) {

    //alert(JSON.stringify(values))

    var order_type = values[0].value.type;
    var order_id = values[0].value.order_id;
    var product_id = values[0].value.product_id;

    window.location.href = '#popup-product';

    if (order_type == 'Purchase') {

      ReactDOM.render(
        <>
          <h3>Purchase</h3> 

          Order ID: {order_id}
          <br></br>
          Product ID: {product_id}

          <div className="popup-table">
            <div className="popup-tableBody">
              <div className="popup-tableRow">
              <div className="popup-tableCell">1</div>
              <div className="popup-tableCell">2</div>
              </div>
              <div className="popup-tableRow">
              <div className="popup-tableCell">3</div>
              <div className="popup-tableCell">4</div>
              </div>
              <div className="popup-tableRow">
              <div className="popup-tableCell">5</div>
              <div className="popup-tableCell">6</div>
              </div>
            </div>
          </div>
        </>
      , document.getElementById("popup-content")
      );
    }

    if (order_type == 'Return') {

      ReactDOM.render(
        <>
          <h3>Return</h3> 

          Order ID: {order_id}
          <br></br>
          Product ID: {product_id}

          <div className="popup-table">
            <div className="popup-tableBody">
              <div className="popup-tableRow">
              <div className="popup-tableCell">1</div>
              <div className="popup-tableCell">2</div>
              </div>
              <div className="popup-tableRow">
              <div className="popup-tableCell">3</div>
              <div className="popup-tableCell">4</div>
              </div>
              <div className="popup-tableRow">
              <div className="popup-tableCell">5</div>
              <div className="popup-tableCell">6</div>
              </div>
            </div>
          </div>
        </>
      , document.getElementById("popup-content")
      );

    }
  }




  function QuestionIconPopup3() {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className="question-popup">
        <div className="question-icon" onClick={togglePopup}>
          
        </div>
        {isOpen && (
          <div className="popup-content">
            Once the return window is closed, any items with a "Tryout" 
            status will be automatically purchased, and the payment will 
            be automatically charged to your selected payment method.
          </div>
        )}
      </div>
    );
  }

  function QuestionIconPopup2() {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className="question-popup">
        <div className="question-icon" onClick={togglePopup}>
         
        </div>
        {isOpen && (
          <div className="popup-content">
            When your delivery is in transit status, it signifies our dedicated efforts to 
            swiftly deliver your item(s). Typically, this process is completed within a maximum of two business days. 
          </div>
        )}
      </div>
    );
  }

  function QuestionIconPopup1() {
    const [isOpen, setIsOpen] = useState(false);
    const togglePopup = () => {
      setIsOpen(!isOpen);
    };
    return (
      <div className="question-popup">
        <div className="question-icon" onClick={togglePopup}>
          
        </div>
        {isOpen && (
          <div className="popup-content">
            Your order has been successfully placed. Please sit back and relax while we work on delivering it to you.
          </div>
        )}
      </div>
    );
  }

  const troutHours = 96;

  function insertTryonCountdown(delivered_at, countdown_id){

    //alert("delivered_at", delivered_at)
    //alert("countdown_id", countdown_id)
    
    //COUNT DOWN TRYOUT TIME
    // Set the target date and time (in UTC)
    var targetDate = new Date(delivered_at).getTime();

    // Calculate the target date and time for 72 hours later
   
    
    // Update the countdown every 1 second
    var countdownInterval = setInterval(updateCountdown(targetDate), 1000);

    function updateCountdown(targetDate) {

      //alert("HERE1", countdown_id)
      
      var targetDateHoursLater = targetDate + (troutHours * 60 * 60 * 1000); // 72 hours in milliseconds
      var currentDate = new Date().getTime();
      var timeRemaining = targetDateHoursLater - currentDate;

      if (timeRemaining <= 0) {
        //alert("HERE2")
        clearInterval(countdownInterval);
        //alert("HERE3", countdown_id)
        try{document.getElementById(countdown_id).innerHTML = "Closed";} 
        catch{}
        
      } else {

        
        var hours = Math.floor(timeRemaining / (1000 * 60 * 60));
        var minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        //var seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        //const countdownText = `Will be closed in ${hours}h ${minutes}m ${seconds}s left`;
        var countdownText = `Will be closed in ${hours}h ${minutes}m`;
        //alert("countdownText" + countdownText)

        try{document.getElementById(countdown_id).innerHTML = countdownText;}
        catch{}
      }
    }

  }



    function loadOrders() {

        var http = typeof window !== "undefined" ? new XMLHttpRequest() : ""
        var url = 'https://api.goflyy.com/v1/client/getOrdersList';
        var data = typeof window !== "undefined" ? new FormData() : ""
        
        data.append("email", "privaclouds@gmail.com");
        data.append("limit", "10");
        data.append("skip", "0");
        data.append("age_days", "2366");
        data.append("type_order", "");
      
        http.open('POST', url, true);
        http.setRequestHeader("token", "cuawkuoghpmhckqrrmbusqtblgjztpsbdqqelgabbbnctzxcnifhvgklbpravtktzrhfohhswiobvhphoridkbhbbybhrixgkyzinogcxiuxszrtikancopbumadqfnf");
        http.send(data)

        http.onreadystatechange = function() {

          if(http.readyState == 4 && http.status == 200) {

              var response = http.responseText;
              const resp_obj = JSON.parse(response); 

              //alert(JSON.stringify(resp_obj))

              //GENERATE HTML SECTIONS PER EACH ORDER
              const numberOfOrderObjects = resp_obj.length;
              //alert(numberOfOrderObjects)
              var html_orders_section = ''
              for (var i = 0; i < numberOfOrderObjects; i++) {
                var html_orders_section = html_orders_section.concat("<div id='order-section-orderid-" + resp_obj[i].order_id + "'></div>");
              }
              //alert(html_orders_section);
              document.getElementById('data-section').innerHTML = html_orders_section;

              //API FUNCTION REQUEST WAIT UNTILL RESPONDED TO MOVE FORWARD
              function httpRequest(reqType, address, email, order_id, asyncProc) {
                var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
                var data2 = typeof window !== "undefined" ? new FormData() : ""
                data2.append("email", email);
                data2.append("order_id", order_id);
                if (asyncProc) {req.onreadystatechange = function() { 
                    if (this.readyState == 4 && this.status == 200 && this.responseText !== null) {
                      asyncProc(this);
                    } 
                  };
                }
                //typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
                req.open(reqType, address, !(!asyncProc))
                req.setRequestHeader("token", "cuawkuoghpmhckqrrmbusqtblgjztpsbdqqelgabbbnctzxcnifhvgklbpravtktzrhfohhswiobvhphoridkbhbbybhrixgkyzinogcxiuxszrtikancopbumadqfnf");
                req.send(data2)
                //typeof window !== "undefined" ? req.send(data2) : ""
                return req;
                }
              
              
              //REQUEST EACH ORDER SEPARATELY
              for (var order_listed of resp_obj) {

                //alert("HERE" + JSON.stringify(order_listed))

                //FORMAT DATE OF CREATION
                var formattedDateCreatedAt = '';
                try {
                  const date_of_creation = new Date(order_listed.created_at);
                  const options_datetime = { year: 'numeric', month: 'long' };
                  var formattedDateCreatedAt = date_of_creation.toLocaleDateString('en-US', options_datetime);
                } catch {}

                //alert(formattedDateCreatedAt)

                //API REQUEST WAIT UNTILL RESPONDED TO MOVE FORWARD
                var order_response = httpRequest("POST", "https://api.goflyy.com/v1/client/getMyOrder", "privaclouds@gmail.com", order_listed.order_id );  
                var order_response_text = order_response.responseText;
                const order_response_obj = JSON.parse(order_response_text)[0]; 
                //alert("order_response_text", order_response_text)

                //CHECK OPTIONAL ADDRESS2 
                if (!order_response_obj.dropoff_address2) { var dropoff_address2 = ''; }
                else { var dropoff_address2 = order_response_obj.dropoff_address2; }
                
                //GENERATE DYNAMIC PRODUCTS SECTION PER EACH ORDER
                var products_section = 'products-section-' + order_listed.order_id;

                //alert(order_response_obj.order_delivered_at)

                //alert(order_listed.order_id)


                //alert("order_response_obj.order_delivered_at", order_response_obj.order_delivered_at)

                var countdown_id = "countdown_" + order_listed.order_id;


                //ADD TIMELINE AND PRODUCT SECTION(S) FOR ORDER
                ReactDOM.render(
                  <>

                      <td colspan="5" className="order-month">{formattedDateCreatedAt}
          
                      <div className="order-id">Order ID: {order_listed.order_id}</div>
                      
                      </td>
                      
                      <div id={products_section}></div>

                      <tr>
                        <td colspan="5">
                        
                          <div className="timeline" style={{'margin-top':'10px', 'margin-bottom':'10px'}}>
                            <div className="timelineBody">
                                <div className="timelineRow">
                                </div>
                                <div className="timelineRow">
                                  <div className="timelineCell">
                                    <QuestionIconPopup1 />
                                      <div className="order-status-ordered">
                                        <p>Ordered</p>
                                        <div className="timeline-status-smalltext">{order_listed.created_at}</div>
                                      </div>
                                  </div>
                                  <div className="timelineCell">

                                        {order_response_obj.order_delivered_at === null ? (
                                          <>  
                                            <QuestionIconPopup2 />
                                            <div className="order-status-delivered-inactive">
                                              <p>Delivered</p>
                                              <div className="timeline-status-smalltext">In Transit</div>
                                            </div>
                                          </>
                                        ) : (
                                          <>  
                                            <QuestionIconPopup2 />
                                            <div className="order-status-delivered">
                                              <p>Delivered</p>
                                              <div className="timeline-status-smalltext">{order_response_obj.order_delivered_at}</div>
                                            </div>
                                          </>
                                        )}

                                  </div>
                                  <div className="timelineCell">

                                        {order_response_obj.order_delivered_at === null ? (
                                          <>  
                                          <QuestionIconPopup3 />
                                          <div className="order-status-tryout-inactive">
                                           
                                            <p>Return Window</p>
                                            <div className="timeline-status-smalltext">Opens after delivery</div>
                                            
                                          </div>
                                          </>
                                        ) : (
                                          <>  
                                          <QuestionIconPopup3 />
                                          <div className="order-status-tryout">
                                            
                                            <p>Return Window</p>
                                            <div className="timeline-status-smalltext">
                                              <div id={countdown_id}></div>
                                            </div>
                                          </div>
                                          </>
                                        )}

                                  </div>
                                  
                                </div>
                                
                            </div>
                          </div>

                        </td>
                      </tr>

                      <tr colspan="5" className="order_bottom-border"></tr>
                      <br></br>
     
                  </>
                , document.getElementById('order-section-orderid-' + order_listed.order_id)
                );

                //INSERT COUNTDOWN VALUE PER ORDER
                insertTryonCountdown(order_response_obj.order_delivered_at, countdown_id)

                //GENERATE HTML SECTIONS PER EACH PRODUCT
                var html_products_section = ''
                for (var product_listed of order_response_obj.list_of_products) {
                  var html_products_section = html_products_section.concat("<div id='product-section-orderid-" + order_listed.order_id + "-productid-" + product_listed.product_id + "'></div>");
                }
                //alert("HERE2 " + html_products_section);
                document.getElementById('products-section-' + order_listed.order_id).innerHTML = html_products_section;

                //READ PRODUCT LIST
                for (var product of order_response_obj.list_of_products) {

                  //var popup_address = 'popup-product';

                  var tryoniconid = "tryonorpaidicon-productid-" + product.product_id;
                  var priceid = "priceid-productid-" + product.product_id;




                  var options = [
                    { 
                      value: {"type":"Purchase", "order_id": order_listed.order_id, "product_id": product.product_id},
                      label: "Purchase item"
                    },
                    {
                      value: {"type":"Return", "order_id": order_listed.order_id, "product_id": product.product_id},
                      label: "Return item"
                    }
                  ];
                


                  ReactDOM.render(
                    <>

                      <div id="popup-product" className="popup popup-message bg-white">
                        <div onClick={closePopup} className="close">

                          <div id="close-popup">x</div>

                        </div>
                        <div id="popup-content"></div>
                      </div>

                      <tr>
                        <td className="order-productpic-row" style={{'minWidth':'100px'}}>
                          <img
                            src={"https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg"}
                            alt={"Product title"}
                            className="w-12 h-12 object-cover rounded-full"
                          />
                        </td>

                        <td className="order-product-row" style={{'minWidth':'400px'}}>

                          <div className="order-product-title">{product.product_name}</div>
                          <div className="order-product-size">{'Size: XL'}</div>
                          <div className="order-product-merchant">{'Merchant: ' + order_response_obj.retailer_name}</div>

                        </td>
                      
                        <td className="order-price-row" style={{'width':'120px'}}>
                          <div className="order-price-number" id={priceid}></div>
                          <div id={tryoniconid}></div>
                        </td>

                        <td className="order-address-row" style={{'minWidth':'280px'}}>
                        
                          <div className="order-product-address1" >{order_response_obj.dropoff_address1 + " " + dropoff_address2}</div>
                          <div className="order-product-address2">{order_response_obj.dropoff_city + ", " +  order_response_obj.dropoff_state + " " + order_response_obj.dropoff_zip}</div>

                        </td>
                    
                        <td className="order-action-row" style={{'minWidth':'220px'}}>

                          <Select 
                              id={order_listed.order_id}
                              //loading={true}
                              //separator={true}
                              //searchable={true}
                              color='#d3d3d3'
                              placeholder="Select action"
                              value={order_listed.order_id}
                              
                              //addPlaceholder="Select Pre-trained model"
                              //dropdownHandle={true}
                              //dropdownHeight="300px"
                              //dropdownGap={3}
                              //backgroundColor ="blue"
                              //style={CustomStyle}
                              style={{"color":"balck", "height":"50px", "padding":"20px", "fontSize":"16px", "background-color":'white', "borderColor":"#d3d3d3", "border-radius": "8px"}}
                              options={options} 
                              onChange={(values) => setValues(values)} 
                              />

                        </td>
                      </tr>
                    </>

                  , document.getElementById("product-section-orderid-" + order_listed.order_id + "-productid-" + product.product_id)
                  );

                  //UPDATE TRYON OR PAID OR RETURNED STATUS ICONS
                  if (product.product_status == "Paid") {
                    document.getElementById(priceid).innerHTML = currencyFormatter.format(product.product_price);
                    document.getElementById(tryoniconid).className = 'paidicon';
                    document.getElementById(tryoniconid).innerHTML = "Paid";
                  } 

                  if (product.product_status == "Tryon") {
                    document.getElementById(priceid).innerHTML = currencyFormatter.format(product.product_price);
                    document.getElementById(tryoniconid).className = 'tryonicon';
                    document.getElementById(priceid).className = 'order-price-number linethrough';
                    document.getElementById(tryoniconid).innerHTML = "Tryon";


                  }
                  
                  if (product.product_status == "Returned") {
                    document.getElementById(priceid).innerHTML = currencyFormatter.format(product.product_price);
                    document.getElementById(tryoniconid).className = 'returnedicon';
                    document.getElementById(priceid).className = 'order-price-number linethrough';
                    document.getElementById(tryoniconid).innerHTML = "Return";
                    
                  }

                  if (product.product_status == "Tryon Returned") {
                    document.getElementById(priceid).innerHTML = currencyFormatter.format(product.product_price);
                    document.getElementById(tryoniconid).className = 'returnedicon';
                    document.getElementById(priceid).className = 'order-price-number linethrough';
                    document.getElementById(tryoniconid).innerHTML = "Return";
                  }


                }
              }
            }
          }



    }
  


    useEffect(() => {

      loadOrders();

    }, []);





    const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };
  
    const list = (anchor) => (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  





  return (
  <>

      <Nav />

      <SidebarOrders side={side} closeSidebar={closeSidebar} />
      
      <div className="absolute left-0 sm:left-64 md:left-64 md:left-64 lg:left-64 xl:left-64 2xl:left-64 py-12 right-10 mx-16 " >
        <div className="flex flex-wrap" >

          <div id="data-section"><br></br><br></br><br></br>Loading...</div>

        </div>
      </div>

      <div style={{'margin-top':'200px'}}>
     
        <React.Fragment key={'right'}>
          <Button onClick={toggleDrawer('right', true)}>lisdgfisgfilsgldifglisudgilfsglidfglisudgfliusglidfugsildugflisdufglisdufgl</Button>
          <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
          >
            {list('right')}
          </Drawer>
        </React.Fragment>

    </div>

    </>
  );
};
export default Marketplace;