import { Link } from "react-router-dom";
import { useCatProductsQuery } from "../../store/services/homeProducts";
import ProductSkeleton from "./ProductSkeleton";
import ProductCard from "./ProductCard";
const HomeProduct = ({ category }) => {
  //const { data, isFetching } = useCatProductsQuery({
  //  name: category.name,
  ////  page: "",
  //});

  return (
      <>
        <div className="flex justify-between">
          <span className="text-lg font-medium capitalize">
            {category.name}
          </span>
          <span className="capitalize">
            <Link to={`/cat-products/1`}>see all</Link>
          </span>
        </div>
        <div className="flex flex-wrap -mx-5">
          
            <ProductCard product={{'_id':'1','title':'Google','image1':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image2':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image3':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','price':'1000', 'description':'iojronlidfba','discount':'5', 'stars': '4.5', 'reviews':'112', 'sizes':[{'name':'X'}, {'name':'XL'}, {'name':'XXL'}], 'colors':[{'color':'Red'}, {'color':'Blue'}, {'color':'Green'}] }} key={'1'} />
            <ProductCard product={{'_id':'1','title':'Google','image1':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image2':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image3':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','price':'1000', 'description':'iojronlidfba','discount':'5', 'stars': '4.5', 'reviews':'112', 'sizes':[{'name':'X'}, {'name':'XL'}, {'name':'XXL'}], 'colors':[{'color':'Red'}, {'color':'Blue'}, {'color':'Green'}] }} key={'1'} />
            <ProductCard product={{'_id':'1','title':'Google','image1':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image2':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image3':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','price':'1000', 'description':'iojronlidfba','discount':'5', 'stars': '4.5', 'reviews':'112', 'sizes':[{'name':'X'}, {'name':'XL'}, {'name':'XXL'}], 'colors':[{'color':'Red'}, {'color':'Blue'}, {'color':'Green'}] }} key={'1'} />
            <ProductCard product={{'_id':'1','title':'Google','image1':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image2':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','image3':'https://elisabethmcknight.com/wp-content/uploads/2021/09/Shein-Kids-Reviews-0328-684x1024.jpg','price':'1000', 'description':'iojronlidfba','discount':'5', 'stars': '4.5', 'reviews':'112', 'sizes':[{'name':'X'}, {'name':'XL'}, {'name':'XXL'}], 'colors':[{'color':'Red'}, {'color':'Blue'}, {'color':'Green'}] }} key={'1'} />
            
        </div>
      </>
    
  );
};

export default HomeProduct;
