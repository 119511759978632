import { Virtual } from "swiper";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import Skeleton from "../skeleton/Skeleton";
import Thumbnail from "../skeleton/Thumbnail";
const Categories = () => {
  //const { data, isFetching } = useAllCategoriesQuery();

  const isFetching = ''

  let i = 1;
  return (

      <Swiper
        modules={[Virtual]}
        spaceBetween={20}
        slidesPerView={6}
        virtual
        className="w-full h-[150px] mb-10"
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          1080: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
          },
        }}
      >
        
            <SwiperSlide
              className="w-full overflow-hidden  rounded-lg relative"
              key={1}
              virtualIndex={1}
            >
              <div className="w-full h-[150px] rounded-lg overflow-hidden">
                <img
                  src={`./images/slider/1.jpg`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="absolute inset-0 w-full h-full bg-black/50 flex items-center justify-center p-4">
                <Link
                  to={`/marketplace`}
                  className="text-white text-base font-medium capitalize"
                >
                  Collection 1
                </Link>
              </div>
            </SwiperSlide>

            <SwiperSlide
              className="w-full overflow-hidden  rounded-lg relative"
              key={2}
              virtualIndex={2}
            >
              <div className="w-full h-[150px] rounded-lg overflow-hidden">
                <img
                  src={`./images/slider/2.jpg`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="absolute inset-0 w-full h-full bg-black/50 flex items-center justify-center p-4">
                <Link
                   to={`/marketplace`}
                  className="text-white text-base font-medium capitalize"
                >
                  Collection 2
                </Link>
              </div>
            </SwiperSlide>

            <SwiperSlide
              className="w-full overflow-hidden  rounded-lg relative"
              key={3}
              virtualIndex={3}
            >
              <div className="w-full h-[150px] rounded-lg overflow-hidden">
                <img
                  src={`./images/slider/3.jpg`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="absolute inset-0 w-full h-full bg-black/50 flex items-center justify-center p-4">
                <Link
                   to={`/marketplace`}
                  className="text-white text-base font-medium capitalize"
                >
                  Collection 3
                </Link>
              </div>
            </SwiperSlide>

            <SwiperSlide
              className="w-full overflow-hidden  rounded-lg relative"
              key={4}
              virtualIndex={4}
            >
              <div className="w-full h-[150px] rounded-lg overflow-hidden">
                <img
                  src={`./images/slider/4.jpg`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="absolute inset-0 w-full h-full bg-black/50 flex items-center justify-center p-4">
                <Link
                   to={`/marketplace`}
                  className="text-white text-base font-medium capitalize"
                >
                  Collection 4
                </Link>
              </div>
            </SwiperSlide>

            <SwiperSlide
              className="w-full overflow-hidden  rounded-lg relative"
              key={5}
              virtualIndex={5}
            >
              <div className="w-full h-[150px] rounded-lg overflow-hidden">
                <img
                  src={`./images/slider/5.jpg`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="absolute inset-0 w-full h-full bg-black/50 flex items-center justify-center p-4">
                <Link
                  to={`/marketplace`}
                  className="text-white text-base font-medium capitalize"
                >
                  Collection 5
                </Link>
              </div>
            </SwiperSlide>

            <SwiperSlide
              className="w-full overflow-hidden  rounded-lg relative"
              key={1}
              virtualIndex={1}
            >
              <div className="w-full h-[150px] rounded-lg overflow-hidden">
                <img
                  src={`./images/slider/1.jpg`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              <div className="absolute inset-0 w-full h-full bg-black/50 flex items-center justify-center p-4">
                <Link
                   to={`/marketplace`}
                  className="text-white text-base font-medium capitalize"
                >
                 Collection 6
                </Link>
              </div>
            </SwiperSlide>

            
       
      </Swiper>
    
  );
  return <div>Categories</div>;
};

export default Categories;
