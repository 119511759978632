
import { useState, useEffect } from "react";
import ReactDOM from 'react-dom';

import Categories from "../../components/home/Categories";

import ProductCard from "../../components/home/ProductCard";

import HomeProduct from "../../components/home/HomeProduct";
import Nav from "../../components/home/Nav";
import Slider from "../../components/home/Slider";
import { useRandomCategoriesQuery } from "../../store/services/categoryService";

import SidebarAccount from "../../components/SidebarAccount";
import Select from "react-dropdown-select";

import AdminNav from "../../components/AdminNav";

import './style.css'

const Marketplace = () => {
  //const { data, isFetching } = useRandomCategoriesQuery();

  const isFetching = ''

  const [side, setSide] = useState('-left-64')
  const openSidebar = () => {
      setSide("left-0");
  }
  const closeSidebar = () => {
      setSide('-left-64');
  }

  //alert("MPLC")

  const options = [
    { 
      value: 0,
      label: "Purchase item"
    },
    {
      value:  1,
      label: "Return item"
    }
  ];



  function setValues(values, product_id){

    var value = values[0].value;

    window.location.href = "#popup1";


    if (value == '0') {

      ReactDOM.render(
        <>
  
          <h3>Purchase</h3> 

          Product ID: {product_id}

          <div class="popup-table">
            <div class="popup-tableBody">
              <div class="popup-tableRow">
              <div class="popup-tableCell">1</div>
              <div class="popup-tableCell">2</div>
              </div>
              <div class="popup-tableRow">
              <div class="popup-tableCell">3</div>
              <div class="popup-tableCell">4</div>
              </div>
              <div class="popup-tableRow">
              <div class="popup-tableCell">5</div>
              <div class="popup-tableCell">6</div>
              </div>
            </div>
          </div>
  
        </>
      , document.getElementById("popup-content")
      );

    }

    if (value == '1') {

      ReactDOM.render(
        <>
  
          <h3>Return</h3> 

          Product ID: {product_id}
  
        </>
      , document.getElementById("popup-content")
      );

    }

   

    


    

    //style={{'filter':'brightness(0.3)'}}
  
    //document.getElementById("head").style.filter = "brightness(0.3)";
    //document.getElementById("head").className = "brightnessOverlay";

    //document.getElementById("popup1").style.filter = "brightness(1)";
    //document.getElementById("popup1").className = "brightnessOverlay2";
    

  }





  useEffect(() => {

    ReactDOM.render(
      <>

        123

        
      </>
    , document.getElementById('data-table-section')
    );
  
  }, []);








  return (
  <>
  <section className="">
    <div id="head" >
      <Nav />

      <SidebarAccount side={side} closeSidebar={closeSidebar} />
      
      <div className="absolute left-0 sm:left-64 md:left-64 md:left-64 lg:left-64 xl:left-64 2xl:left-64 py-12 right-10 mx-16" >
        <div className="flex flex-wrap" >

        <div id="data-table-section"></div>

        </div>
      </div>

    </div>
    </section>
    </>
  );
};
export default Marketplace;
